import React, { useDebugValue, useEffect, useState } from "react";
import api from "../../apiBaseConfig";
import { UPLOAD } from "../../apiurl";
import _get from "lodash/get";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { Avatar } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";

function ScreenShot(props) {

  const [enlarge, setEnlarge] = useState(null);
  const [screenShots, setScreenShots] = useState(null)

  useEffect(() => {
    let is_mandatory = false
    let screenShot = props.screenShot.map((doc, index) => {
      doc.index = index;
      if(doc.is_mandatory) is_mandatory = true
      return doc
    });
    screenShot = screenShot.filter(item=>item.doc_type!==4)
    setScreenShots(screenShot)
  }, [props])

  const uploadScreenShot = async (docId, screenShot) => {
    if (screenShot && props.caseId) {
      let response = await api.put(UPLOAD, {
        connection: props.connectionId,
        case: props.caseId,
        front_upload: screenShot,
        document: docId, //payload.document,
        corrected_ocr: {},
        extracted_ocr: {},
        document_upload_event: "on_call"
      });
    }
  };

  const handleClick = (key) => {
      takeScreenShot(key);
  }

  const takeScreenShot = (index) => {
    let capturedShot = props.takeScreenShot();
    let documents = props.screenShot;
    documents[index].image = capturedShot;
    uploadScreenShot(documents[index].id, capturedShot);
    props.handleScreenshot(documents);
    // setScreenShot({ ...screenShot, [doc]: capturedShot });
  };
  return (
    <div className="border rounded p-4 bg-f9 mt-3">
      <h6 className="txt-light-blue dashed-title mb-3 ltr-spc-1">
        Screenshots
      </h6>
      {screenShots && screenShots.map((item,index) => (
        <div className="mt-3 border dashed-brdr rounded p-2 bg-white">
          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
            <div class="card-body py-2 pl-3 pr-2">
              <div class="d-flex align-items-center dashed-border-bottom pb-2 mb-3">
                {
                  item.image
                    ?
                    <React.Fragment>
                      <img
                        src={item.image}
                        className="rounded-circle border mr-1"
                        onMouseEnter={() => setEnlarge(item.index + "main")}
                        onMouseLeave={() => setEnlarge(null)}
                        width="30"
                        height="30"
                        alt={item.name_display || item.name}
                      />
                      {
                        enlarge === item.index + "main" &&
                        <img
                          src={item.image}
                          className="rounded-circle border enlarge"
                          alt=""
                        />
                      }
                    </React.Fragment>
                    :
                    <Avatar>
                      <PhotoCameraIcon />
                    </Avatar>
                }
                <div class="fnt-sml-x txt-gray ml-2 flex-grow-1 w-100">{item.name}</div>
                <a role="button" onClick={() => handleClick(index)} class="fnt-sml-x ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 28.571 25" fill="#007bff">
                    <path d="M189.259,199.479a3.571,3.571,0,1,1-5.051,0,3.571,3.571,0,0,1,5.051,0" transform="translate(-172.448 -188.612)"></path>
                    <path d="M25,34.093H22.532l-1.787-3.571H7.824L6.039,34.1l-2.464,0A3.578,3.578,0,0,0,.01,37.669L0,51.95a3.576,3.576,0,0,0,3.571,3.572H25a3.575,3.575,0,0,0,3.571-3.571V37.665A3.575,3.575,0,0,0,25,34.093ZM14.285,51.058a7.143,7.143,0,1,1,7.143-7.143,7.151,7.151,0,0,1-7.143,7.143Z" transform="translate(0 -30.522)"></path>
                  </svg>
                </a>
              </div>
              <div class="row mb-2 m-0">
                {screenShots &&
                  screenShots.map((doc, itemIndex) =>
                  index === itemIndex && doc.image &&
                    <div class="col d-flex align-items-center mb-2" style={{ minWidth: '33%', maxWidth: '33%' }}>
                      <img
                        src={doc.image}
                        className="rounded-circle border mr-1"
                        onMouseEnter={() => setEnlarge(doc.index)}
                        onMouseLeave={() => setEnlarge(null)}
                        width="20"
                        height="20"
                        alt={doc.name_display}
                      />
                      {
                        enlarge === doc.index &&
                        <img
                          src={doc.image}
                          className="rounded-circle border enlarge"
                          alt=""
                        />
                      }
                      <div class="fnt-sml-x txt-gray ml-2 flex-grow-1 w-100">{doc.name_display || doc.name}
                        <RefreshIcon style={{ width: "15px" }} class="ml-1 pointer" onClick={() => handleClick(index)} />
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="row mt-2">

      </div>
    </div>
  );
}

export default ScreenShot;
